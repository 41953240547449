// extracted by mini-css-extract-plugin
export var a = "slug-module--a--64757";
export var abstract = "slug-module--abstract--2d93a";
export var authorInfoRow = "slug-module--author-info-row--7cd3a";
export var authorName = "slug-module--author-name--cbfc0";
export var body = "slug-module--body--4d409";
export var hr = "slug-module--hr--ec583";
export var image = "slug-module--image--997dd";
export var mdxText = "slug-module--mdx-text--b7904";
export var row = "slug-module--row--51ff6";
export var title = "slug-module--title--321b7";