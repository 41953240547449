import * as React from "react";
import Container from "react-bootstrap/Container";
import { Link } from "gatsby";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../../components/layout";
import PrimaryButton from "../../styles/styled-component/PrimaryButton";
import {
  authorInfoRow,
  title,
  hr,
  row,
  abstract,
  authorName,
  body,
  mdxText,
} from "./slug.module.css";
import GatsbyImageAvatar from "../../styles/styled-component/Avatar";
import { SEO } from "../../components/seo";

const BlogPost = ({ data }) => {
  const heroImage = getImage(data.mdx.frontmatter.hero_image);
  const authorImage = getImage(data.mdx.frontmatter.author_image);
  const logoImage = getImage(data.mdx.frontmatter.logo_image);

  return (
    <Layout pageTitle={data.mdx.frontmatter.title}>
      {/* COLORED HEADER + IMAGE */}
      <Container fluid style={{ backgroundColor: data.mdx.frontmatter.color }}>
        <Row className={row}>
          <Col md={6}>
            <GatsbyImage
              image={heroImage}
              alt={data.mdx.frontmatter.hero_image_alt}
            />
          </Col>
          <Col md={6}>
            <h2 className={title}>{data.mdx.frontmatter.title}</h2>
            <p className={abstract}>{data.mdx.frontmatter.abstract}</p>
          </Col>
        </Row>
      </Container>

      {/* BODY */}
      <Container fluid className={body}>
        <Row className={authorInfoRow}>
          <Col xs={3} sm={2}>
            <GatsbyImageAvatar
              image={authorImage}
              alt={data.mdx.frontmatter.author}
            />
          </Col>
          <Col xs={4} sm={5}>
            <p className={authorName} style={{ margin: "0" }}>
              {data.mdx.frontmatter.author}
            </p>
            <p style={{ color: "#A8A8A8", margin: "0" }}>
              {data.mdx.frontmatter.date} ・ {data.mdx.timeToRead} min read
            </p>
          </Col>
          <Col xs={4} sm={3} style={{ textAlign: "right" }}>
            <GatsbyImage image={logoImage} alt="logo" />
          </Col>
        </Row>
        <hr className={hr} />
        <h2 style={{ margin: "20px 0 20px" }}>{data.mdx.frontmatter.title}</h2>
        <div className={mdxText}>
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
        </div>

        <div style={{ textAlign: "center" }}>
          <p style={{ margin: "30px 0" }}>
            Did you find this article helpful?{" "}
            <Link
              to="/contact"
              style={{ color: "#0097be", fontStyle: "italic" }}
            >
              Let us know!
            </Link>
          </p>
          {data.mdx.frontmatter.hero_cred ? (
            <p style={{ fontStyle: "italic" }}>
              Photo(s) by {data.mdx.frontmatter.hero_cred}
            </p>
          ) : (
            ""
          )}
          <PrimaryButton style={{ color: "white" }}>
            <Link to="/contact">Contact us</Link>
          </PrimaryButton>
        </div>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        author
        abstract
        seo_description
        seo_keywords
        date(formatString: "MMMM D, YYYY")
        color
        hero_image_alt
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
        hero_cred
        author_image {
          childImageSharp {
            gatsbyImageData(height: 80)
          }
        }
        logo_image {
          childImageSharp {
            gatsbyImageData(height: 80)
          }
        }
        category
      }
      body
      timeToRead
    }
  }
`;

export default BlogPost;

export const Head = ({ location, data }) => {
  const imagePath = getSrc(data.mdx.frontmatter.hero_image);
  return (
    <SEO
      title={data.mdx.frontmatter.title}
      description={data.mdx.frontmatter.seo_description}
      keywords={data.mdx.frontmatter.seo_keywords}
      author={data.mdx.frontmatter.author}
      image={imagePath}
      pathname={location.pathname}
    />
  );
};
