import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";

const GatsbyImageAvatar = styled(GatsbyImage)`
  border-radius: 50%;
  img {
    border-radius: 50%;
  }
`;

export default GatsbyImageAvatar;
